import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

const db = getDatabase();

if ( window.location.hostname === "localhost" ) {
console.log ( "using database emulator: " + window.location.hostname );
   connectDatabaseEmulator(db, "localhost", 9000);
} else if ( window.location.hostname === "192.168.68.142" ) {
console.log ( "using database emulator: " + window.location.hostname );
   connectDatabaseEmulator(db, "192.168.68.142", 9000);
} 


let startApp = () => {

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render( <App /> );

}


startApp()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(); // unregister

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
