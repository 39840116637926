import React, { createContext, useContext, useEffect, useState } from 'react'

import { useAuth } from './AuthContext'

import { getDatabase, ref, onValue } from 'firebase/database';

const DatabaseContext = createContext({

  artccs: [],
  flightObjects: [],

})


export const useDatabase = () => useContext(DatabaseContext)

export default function DatabaseContextProvider({ children }) {

  const { currentUser } = useAuth()

  const [artccs, setArtccs] = useState([]);
  const [flightObjects, setFlightObjects] = useState([]);

  useEffect(() => {

     if ( currentUser == null ) return;    

     const db = getDatabase();

     let array = [];
     let dref = ref(db, "artccs");

     async function getArtccData() {
        await onValue(dref, function(snapshot) {
           if ( snapshot.exists() ) {
              array = [];
              let data = snapshot.val();
              for ( let i in data ) {
                 var t = JSON.stringify(data[i]);
                 array.push(t);
                 setArtccs ( [...array] );
              }
           }
        })
     }
     getArtccData();

     array = [];
     dref = ref(db, "flightObjects");

     async function getFlightObjectData() {
        await onValue(dref, function(snapshot) {
           if ( snapshot.exists() ) {
              array = [];
              let data = snapshot.val();
              for ( let i in data ) {
                 array.push(data[i]);
                 setFlightObjects ( [...array] );
              }
           }
        })
     }
     getFlightObjectData();

  }, [currentUser]);


  const value = {
    artccs,
    flightObjects,
  }

  return <DatabaseContext.Provider value={value}>{children}</DatabaseContext.Provider>

};

