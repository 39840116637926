import React from 'react'
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'

import DatabaseContextProvider from '../contexts/DatabaseContext'

import SignInUI from '../pages/SignInUI'
import SimServMap from '../pages/SimServMap'

export default function AppRouter(props) {

  const { currentUser } = useAuth()

  if (!currentUser) {

    // user is not authenticated
    return ( <SignInUI /> );

  }

  return (
    <>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />} path='/' component={SignInUI} key="signIn1" />
          <Route element={<ProtectedRoute />} path='/login' component={SignInUI} key="signIn2" />
          <Route element={<ProtectedRoute />} path='/map' component={SimServMap} key="simServMap" />
        </Routes>
      </Router>
    </>
  )

}

/*
export const ProtectedRoute = ({ children }) => {

  const { currentUser } = useAuth();

  if (!currentUser) {

    // user is not authenticated
    return <Navigate to="/login" />;

  }

  return ( 
     <DatabaseContextProvider>
        <Navigate to="/map" />;
     </DatabaseContextProvider>
  );


};
*/

function ProtectedRoute(props) {

   const { currentUser } = useAuth()
   const { path } = props
   const location = useLocation()

   if ( path === '/' || path === '/login' ) {
      if ( currentUser ) {
        return ( <Navigate to={location.state?.from ?? '/map'} /> );
      }
   }

   if ( currentUser ) {

     return ( 
        <DatabaseContextProvider>
          <SimServMap />
        </DatabaseContextProvider>
     );

   }

   return ( <SignInUI /> );

}
