import React from 'react';
import {APIProvider, Map} from '@vis.gl/react-google-maps';

function SimServMap() {

  return (
     <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
       <Map
         style={{width: '100vw', height: '100vh'}}
         defaultCenter={{lat: 39.65875285001389, lng: -75.07682019530469}}
         defaultZoom={7}
         gestureHandling={'greedy'}
         disableDefaultUI={true}
       />
     </APIProvider>
  );

}

export default SimServMap;
